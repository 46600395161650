
































































































/* eslint-disable quote-props */
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import LineSplitter from '@/components/animation/LineSplitter.vue'

import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'

export default defineComponent({
  name: 'solution-title',
  props: {
    index: {
      type: Number,
      required: true,
    },
    solution: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
  components: { LineSplitter },
  setup(props) {
    const formatedIndex = () => (props.index + 1).toString().padStart(2, '0')

    const rootRef = ref()
    const indexRef = ref()
    const iconRef = ref()
    const titleRef = ref()

    onMounted(() => {
      if (props.animate && motionOK()) {
        console.log(rootRef)

        gsap.set(rootRef.value, {
          '--progress': 0,
        })

        gsap.set(indexRef.value, {
          opacity: 0.5,
          yPercent: 100,
        })

        if (iconRef.value) {
          gsap.set(iconRef.value, {
            opacity: 0.5,
            yPercent: 100,
          })
        }
      }
    })

    const getTween = () => {
      const TL = gsap.timeline({
        defaults: {
          ease: 'power4.out',
          duration: 1,
        },
      })

      TL.to(
        indexRef.value,
        {
          yPercent: 0,
          opacity: 1,
        },
        'start'
      )

      if (iconRef.value) {
        TL.to(
          iconRef.value,
          {
            yPercent: 0,
            opacity: 1,
          },
          'start+=0.1'
        )
      }

      TL.add(titleRef.value.getTween(), 'start+=0.2')

      TL.to(
        rootRef.value,
        {
          '--progress': 1,
          duration: 2,
          ease: 'power4.inOut',
        },
        'start+=0.3'
      )

      return TL
    }

    return {
      formatedIndex,
      ref,
      getTween,
      titleRef,
      indexRef,
      iconRef,
      rootRef,
    }
  },
})
